.footer-footer1 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.footer-max-width {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-content {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius4);
}
.footer-newsletter {
  gap: 24px;
  width: 500px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.footer-navlink {
  display: contents;
}
.footer-image1 {
  width: 183px;
  height: 183px;
  text-decoration: none;
}
.footer-links {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-end;
}
.footer-column1 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer-footer-links {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer-link1 {
  text-decoration: none;
}
.footer-link2 {
  text-decoration: none;
}
.footer-link3 {
  text-decoration: none;
}
.footer-link4 {
  text-decoration: none;
}
.footer-link5 {
  text-decoration: none;
}
.footer-column3 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer-social-links {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer-link {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer-social-link2 {
  text-decoration: none;
}
.footer-credits {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.footer-row {
  gap: 64px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
@media(max-width: 991px) {
  .footer-newsletter {
    width: 300px;
  }
}
@media(max-width: 767px) {
  .footer-content {
    flex-direction: column;
  }
  .footer-newsletter {
    width: 100%;
  }
  .footer-links {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
  .footer-column1 {
    align-items: center;
  }
  .footer-column1-title {
    text-align: center;
  }
  .footer-footer-links {
    align-self: center;
  }
  .footer-column3 {
    align-items: center;
  }
  .footer-social-link1-title {
    text-align: center;
  }
  .footer-social-links {
    align-self: center;
  }
  .footer-row {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .footer-links {
    flex-direction: column;
  }
  .footer-column1 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer-footer-links {
    align-items: center;
    justify-content: center;
  }
  .footer-column3 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer-social-links {
    align-items: center;
    justify-content: center;
  }
  .footer-credits {
    gap: 0;
  }
  .footer-row {
    align-items: center;
    justify-content: center;
  }
}
